/* src/styles/index.css */

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .custom-scrollbar {
    @apply overflow-x-auto overflow-y-hidden;
  }

  .custom-scrollbar::-webkit-scrollbar {
    @apply h-2 bg-transparent;
  }

  .custom-scrollbar::-webkit-scrollbar-thumb {
    @apply bg-accent/50 rounded-full;
  }

  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    @apply bg-accent;
  }

  .custom-scrollbar::-webkit-scrollbar-track {
    @apply bg-background/30 rounded-full;
  }
}

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari and Opera */
  }
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.font-blocky {
  font-family: 'Press Start 2P', sans-serif;
  letter-spacing: -1px; /* Adjust this value to reduce the gap between letters */
  font-weight: 400; /* Adjust this value to make the text thinner */
}

html, body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

section {
  scroll-snap-align: start;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --text: #ebe2e1;
  --background: #151515;
  --primary: #6dabca;
  --secondary: #685946;
  --accent: #ac9f83;
}

.text {
  color: var(--text);
}

.background {
  background-color: var(--background);
}

.primary {
  color: var(--primary);
}

.secondary {
  color: var(--secondary);
}

.accent {
  color: var(--accent);
}
